import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, vModelText as _vModelText, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "ant-row" }
const _hoisted_3 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_4 = { class: "ant-row" }
const _hoisted_5 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_6 = { class: "ant-row" }
const _hoisted_7 = { class: "ant-col ant-col-xs-7 ant-col-xl-7" }
const _hoisted_8 = { class: "ant-col ant-col-xs-5 ant-col-xl-5" }
const _hoisted_9 = { class: "ant-col ant-col-xs-3 ant-col-xl-3" }
const _hoisted_10 = { class: "ant-col ant-col-xs-9 ant-col-xl-9" }
const _hoisted_11 = { class: "ant-row" }
const _hoisted_12 = { class: "ant-col ant-col-xs-7 ant-col-xl-7" }
const _hoisted_13 = { class: "ant-col ant-col-xs-3 ant-col-xl-3" }
const _hoisted_14 = { class: "ant-col ant-col-xs-8 ant-col-xl-8" }
const _hoisted_15 = { class: "ant-col ant-col-xs-6 ant-col-xl-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!
  const _component_selecionar_empresa_cadastro_compartilhado = _resolveComponent("selecionar-empresa-cadastro-compartilhado")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_selecionar_natureza_conta_contabil_referenciada = _resolveComponent("selecionar-natureza-conta-contabil-referenciada")!
  const _component_selecionar_tipo_conta_contabil = _resolveComponent("selecionar-tipo-conta-contabil")!
  const _component_selecionar_data = _resolveComponent("selecionar-data")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_requisicao_modal = _resolveComponent("requisicao-modal")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    keyboard: false,
    visible: _ctx.modalBase.computedVisivel,
    "onUpdate:visible": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.modalBase.computedVisivel) = $event)),
    style: {"width":"800px"},
    "wrap-class-name": "ss-modal-financeiro"
  }, {
    title: _withCtx(() => [
      _createTextVNode(" Cadastro de Contas Contábeis Referenciadas ")
    ]),
    closeIcon: _withCtx(() => [
      _createVNode(_component_icone, { nome: "voltar" })
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", null, [
        ((_ctx.props.operacao.tipoPermissaoDados === _ctx.EPermissaoDados.Incluir && _ctx.telaBase.permissaoDados.incluir)
                  || (_ctx.props.operacao.tipoPermissaoDados === _ctx.EPermissaoDados.Visualizar && _ctx.telaBase.permissaoDados.alterar))
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("button", {
                type: "button",
                class: "ant-btn ant-btn-lg ant-btn-secondary",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.salvar(true)))
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.modalBase.textoBotaoSalvarNovo), 1)
              ]),
              _createElementVNode("button", {
                type: "button",
                class: "ant-btn ant-btn-lg ant-btn-primary ss-btn-acao-destaque",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.salvar(false)))
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.modalBase.textoBotaoSalvar), 1)
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_a_skeleton, {
        loading: _ctx.telaBase.carregando
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form, { layout: "vertical" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _withDirectives(_createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_a_form_item, {
                    label: "Empresas",
                    class: "ss-margin-campos",
                    rules: [{ required: true }]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_selecionar_empresa_cadastro_compartilhado, {
                        identificadorRecurso: _ctx.telaBase.identificadorRecurso,
                        codigosSelecionados: _ctx.telaBase.empresasSelecionadas,
                        "onUpdate:codigosSelecionados": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.telaBase.empresasSelecionadas) = $event))
                      }, null, 8, ["identificadorRecurso", "codigosSelecionados"])
                    ]),
                    _: 1
                  })
                ], 512), [
                  [_vShow, _ctx.telaBase.apresentarEmpresas]
                ])
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_a_form_item, {
                    label: "Nome",
                    class: "ss-margin-campos",
                    rules: [{ required: true }]
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        maxlength: "250",
                        class: "ant-input",
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.contaContabilReferenciada.nome) = $event))
                      }, null, 512), [
                        [_vModelText, _ctx.state.contaContabilReferenciada.nome]
                      ])
                    ]),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_a_form_item, {
                    label: "Número da Conta",
                    class: "ss-margin-campos",
                    rules: [{ required: true }]
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        class: "ant-input",
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.contaContabilReferenciada.numeroConta) = $event))
                      }, null, 512), [
                        [_vModelText, _ctx.state.contaContabilReferenciada.numeroConta]
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_a_form_item, {
                    label: "Conta Reduzida",
                    class: "ss-margin-campos",
                    rules: [{ required: true }]
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        type: "number",
                        class: "ant-input",
                        min: "0",
                        style: {"text-align":"right"},
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state.contaContabilReferenciada.contaReduzida) = $event))
                      }, null, 512), [
                        [_vModelText, _ctx.state.contaContabilReferenciada.contaReduzida]
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_a_form_item, {
                    label: "Digito",
                    class: "ss-margin-campos",
                    rules: [{ required: true }]
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        class: "ant-input",
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.state.contaContabilReferenciada.digito) = $event)),
                        maxlength: "2"
                      }, null, 512), [
                        [_vModelText, _ctx.state.contaContabilReferenciada.digito]
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_a_form_item, {
                    label: "Natureza",
                    class: "ss-margin-campos",
                    rules: [{ required: true }]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_selecionar_natureza_conta_contabil_referenciada, {
                        valor: _ctx.state.contaContabilReferenciada.natureza,
                        "onUpdate:valor": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.state.contaContabilReferenciada.natureza) = $event))
                      }, null, 8, ["valor"])
                    ]),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_a_form_item, {
                    label: "Tipo",
                    class: "ss-margin-campos",
                    rules: [{ required: true }]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_selecionar_tipo_conta_contabil, {
                        valor: _ctx.state.contaContabilReferenciada.tipo,
                        "onUpdate:valor": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.state.contaContabilReferenciada.tipo) = $event))
                      }, null, 8, ["valor"])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_a_form_item, {
                    label: "Nível",
                    class: "ss-margin-campos",
                    rules: [{ required: true }]
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        type: "number",
                        class: "ant-input",
                        style: {"text-align":"right"},
                        min: "0",
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.state.contaContabilReferenciada.nivel) = $event))
                      }, null, 512), [
                        [_vModelText, _ctx.state.contaContabilReferenciada.nivel]
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_a_form_item, {
                    label: "Código RFB",
                    class: "ss-margin-campos"
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        class: "ant-input",
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.state.contaContabilReferenciada.codigoRfb) = $event))
                      }, null, 512), [
                        [_vModelText, _ctx.state.contaContabilReferenciada.codigoRfb]
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(_component_a_form_item, {
                    label: "Data de Alteração",
                    class: "ss-margin-campos",
                    rules: [{ required: true }]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_selecionar_data, {
                        dataSelecionada: _ctx.state.contaContabilReferenciada.dataAlteracao,
                        "onUpdate:dataSelecionada": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.state.contaContabilReferenciada.dataAlteracao) = $event))
                      }, null, 8, ["dataSelecionada"])
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["loading"]),
      _createVNode(_component_requisicao_modal, {
        visivel: _ctx.modalBase.exibirRequisicaoModal,
        "onUpdate:visivel": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.modalBase.exibirRequisicaoModal) = $event)),
        retornoRequisicao: _ctx.modalBase.retornoRequisicao,
        apresentarBarraProgresso: _ctx.modalBase.apresentarBarraProgresso,
        fraseBarraProgresso: _ctx.modalBase.fraseBarraProgresso
      }, null, 8, ["visivel", "retornoRequisicao", "apresentarBarraProgresso", "fraseBarraProgresso"])
    ]),
    _: 1
  }, 8, ["visible"]))
}