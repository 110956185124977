
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { IContaContabilReferenciada, IContaContabilReferenciadaEmpresa } from '@/models/Entidades/Cadastros/PlanosContas/IContaContabilReferenciada';
import ServicoContaContabilReferenciada from '@/servicos/Cadastros/PlanosContas/ServicoContaContabilReferenciada';
import SelecionarEmpresaCadastroCompartilhado from '@/components/MeuSistema/Empresas/SelecionarEmpresaCadastroCompartilhado.vue';
import SelecionarNaturezaContaContabilReferenciada from '@/components/Cadastros/PlanosContas/SelecionarNaturezaContaContabilReferenciada.vue';
import SelecionarTipoContaContabil from '@/components/Cadastros/PlanosContas/SelecionarTipoCategoriaPlanoConta.vue';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';

export default defineComponent({
  name: 'ContaContabilReferenciadaModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    RequisicaoModal,
    Icone,
    SelecionarEmpresaCadastroCompartilhado,
    SelecionarNaturezaContaContabilReferenciada,
    SelecionarTipoContaContabil,
    SelecionarData,
  },
  emits: ['sincronizarRegistro', 'update:operacao', 'update:visivel'],
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, preencherEmpresasComEstrategiaPermissaoDados, filtrarPermissaoDadosUsuarioMultiEmpresas,
      preencherPermissoesDados, defineEmpresasSelecionadasCadastroCompartilhado, verificaConceitoParaApresentarEmpresas, apresentarMensagemSucesso,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso, sincronizarRegistro, defineTextoPadraoBotoes,
    } = useModalBase(props, emit);
    const servicoContaContabilReferenciada = new ServicoContaContabilReferenciada();
    telaBase.identificadorRecurso = 'CADASTRO_CONTAS_CONTABEIS_REFERENCIADAS';
    telaBase.identificadorPermissao = 'PER_CADASTRO_CONTAS_CONTABEIS_REFERENCIADAS';

    const state = reactive({
      contaContabilReferenciada: {} as IContaContabilReferenciada,
    });

    function objetoInicial() {
      state.contaContabilReferenciada = {} as IContaContabilReferenciada;
      state.contaContabilReferenciada.tipo = 'S';
      state.contaContabilReferenciada.natureza = 1;
    }

    async function salvar(salvarNovo: boolean) {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      state.contaContabilReferenciada.empresas = [];
      if (telaBase.empresasSelecionadas.length > 0) {
        telaBase.empresasSelecionadas.forEach((codigoEmpresa) => {
          const contaContabilReferenciadaEmpresa: IContaContabilReferenciadaEmpresa = { codigo: 0, codigoContaContabilReferenciada: state.contaContabilReferenciada.codigo, codigoEmpresa };
          state.contaContabilReferenciada.empresas.push(contaContabilReferenciadaEmpresa);
        });
      }
      apresentarBarraProgresso();
      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
        retorno = await servicoContaContabilReferenciada.incluir(state.contaContabilReferenciada);
      } else if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
        retorno = await servicoContaContabilReferenciada.alterar(state.contaContabilReferenciada);
      }
      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
          sincronizarRegistro(EPermissaoDados.Incluir, retorno.codigoRegistro);
        } else {
          sincronizarRegistro(EPermissaoDados.Alterar, props.operacao.codigoRegistro);
        }
        apresentarMensagemSucesso(retorno.mensagem);
        if (salvarNovo) {
          objetoInicial();
          const telaOperacao: ITelaOperacao = props.operacao;
          telaOperacao.codigoRegistro = 0;
          telaOperacao.tipoPermissaoDados = EPermissaoDados.Incluir;
          modalBase.computedOperacao = telaOperacao;
        } else {
          modalBase.computedVisivel = false;
        }
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      objetoInicial();
      if (modalBase.computedVisivel) {
        if (props.operacao.listaPermissoesDados.length > 0) {
          await preencherPermissoesDados(props.operacao.listaPermissoesDados);
        } else {
          await obterPermissoes(ETipoPermissao.Dados);
        }
        await preencherEmpresasComEstrategiaPermissaoDados(props.operacao.tipoPermissaoDados, true);

        defineTextoPadraoBotoes(props.operacao.tipoPermissaoDados);
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
          state.contaContabilReferenciada = await servicoContaContabilReferenciada.obter(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
          telaBase.empresasSelecionadas = [];
          if (state.contaContabilReferenciada.empresas.length > 0) {
            state.contaContabilReferenciada.empresas.forEach((contaContabilReferenciadaEmpresa) => {
              telaBase.empresasSelecionadas.push(contaContabilReferenciadaEmpresa.codigoEmpresa);
            });
          }
        } else {
          await defineEmpresasSelecionadasCadastroCompartilhado(props.operacao.empresaSelecionada);
        }
      }

      telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);
      verificaConceitoParaApresentarEmpresas();
      telaBase.carregando = false;
    });

    return {
      telaBase,
      props,
      modalBase,
      state,
      salvar,
      apresentarRetornoRequisicao,
      objetoInicial,
      EPermissaoDados,
    };
  },
});
