
import { defineComponent, ref } from 'vue';
import TelaPadraoCrud from '@/core/components/TelaPadraoCrud.vue';
import { useCrudBase } from '@/core/composables/CrudBase';
import ContaContabilReferenciadaModal from './ContaContabilReferenciadaModal.vue';
import ServicoContaContabilReferenciada from '@/servicos/Cadastros/PlanosContas/ServicoContaContabilReferenciada';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { IOrdenacao } from '@/core/models/Consulta/IOrdenacao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';

export default defineComponent({
  name: 'ContaContabilReferenciada',
  components: {
    TelaPadraoCrud,
    ContaContabilReferenciadaModal,
  },
  setup() {
    const {
      crudBase, preencherEstrutura, exibirModal, sincronizaTelaCrud,
    } = useCrudBase();
    const servicoContaContabilReferenciada = new ServicoContaContabilReferenciada();
    const refCrud = ref<InstanceType<typeof TelaPadraoCrud>>();

    crudBase.estrutura = preencherEstrutura();
    crudBase.estrutura.apresentarEmpresas = true;
    crudBase.estrutura.verificarCadastroCompartilhado = true;
    crudBase.estrutura.botaoPrincipalAcao = 'Nova';
    crudBase.estrutura.mensagemExclusao = 'Você confirma a exclusão da conta contábil referenciada';
    crudBase.estrutura.colunasGrade = [
      {
        title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false, ordering: true,
      },
      {
        title: 'Nome', dataIndex: 'nome', key: 'Nome', position: 2, visible: true, ordering: true, customRenderRow: ECustomRenderRow.LinkAcao,
      },
      {
        title: 'Número da Conta', dataIndex: 'numeroConta', key: 'NumeroConta', position: 3, visible: true, ordering: true,
      },
      {
        title: 'Natureza', dataIndex: 'naturezaExtenso', key: 'Natureza', position: 4, visible: true, ordering: true,
      },
      {
        title: 'Ações', key: 'acoes', position: 5, visible: true, fixed: 'right', width: 100, align: 'center', customRenderRow: ECustomRenderRow.IconeAcoes,
      },
    ];
    crudBase.estrutura.ordenacaoPadraoGrade.push({ identificador: 'NumeroConta', ordem: 'ASC' } as IOrdenacao);
    crudBase.estrutura.ordenacaoPadraoGrade.push({ identificador: 'Codigo', ordem: 'DESC' } as IOrdenacao);

    async function sincronizarRegistro(acao: EPermissaoDados, codigoRegistro: number) {
      if (refCrud.value !== undefined) {
        await refCrud.value.sincronizarRegistro(acao, codigoRegistro);
      }
    }

    return {
      crudBase,
      servicoContaContabilReferenciada,
      exibirModal,
      sincronizaTelaCrud,
      sincronizarRegistro,
      refCrud,
    };
  },
});
